<template>
  <!-- Add autocomplete="new-password" to prevent browsers autocomplete UI bug -->
  <v-text-field
    :value="value"
    class="input__standard input__standard--regular"
    :class="!$attrs.label ? 'input__standard--no-label' : ''"
    clear-icon="mdi-close-circle"
    outlined
    color="default"
    clearable
    autocomplete="new-password"
    v-bind="$attrs"
    v-on="$listeners"
    @input="input"
  />
</template>
<script>
export default {
  name: 'StandardTextField',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },

  methods: {
    input(value) {
      this.$emit('input', value)
    },
  },
}
</script>
