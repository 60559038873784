import { required, min, email, numeric } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'

setInteractionMode('lazy')

extend('required', {
  ...required,
  message: 'The {_field_} field is required.',
})

extend('email', {
  ...email,
  message: 'This {_field_} field must be a valid email',
})

extend('min', {
  ...min,
  message: 'The {_field_} field must have at least 8 characters',
})

extend('numeric', {
  ...numeric,
  message: 'This {_field_} field must be numbers only',
})
