<template>
  <v-dialog v-model="dialog" persistent width="500">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-card>
          <v-card-title>
            <h3 class="text">Verify Password</h3>
          </v-card-title>
          <v-card-subtitle class="mt-3">
            Enter your current password to {{ action }}
          </v-card-subtitle>
          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              name="Password"
              rules="required"
              vid="password"
            >
              <StandardPasswordField
                key="password"
                v-model="password"
                label="Password"
                required
                :error-messages="errors"
                :disabled="isLoading"
              />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="btn--secondary"
              rounded
              text
              small
              :disabled="isLoading"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              class="btn--primary"
              type="submit"
              rounded
              text
              small
              :loading="isLoading"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import StandardPasswordField from '@/components/fields/StandardPasswordField'
import '@/validations/settings'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    StandardPasswordField,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialog: true,
      password: '',
    }
  },

  computed: {
    ...mapState('accountSettings', ['isLoading']),
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('cancel')
      }
    },
  },

  methods: {
    async onSubmit() {
      try {
        const { data } = await this.$store.dispatch(
          'accountSettings/getVerificationToken',
          {
            password: this.password,
          }
        )

        this.$emit('success', {
          token: data.data.token,
          currentPassword: this.password,
        })
        // TODO: set expiry
      } catch (error) {
        this.$refs.form.setErrors({
          password: error.response.data.message,
        })
      }
    },
  },
}
</script>
