<template>
  <v-text-field
    :value="value"
    class="input__standard input__standard--regular"
    outlined
    color="default"
    v-bind="$attrs"
    @input="input"
  >
    <template #append>
      <slot name="append" />
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: 'TextFieldWithAppend',
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  methods: {
    input(value) {
      this.$emit('input', value)
    },

    update(value) {
      this.$emit('update', value)
    },
  },
}
</script>
