<template>
  <!-- Add autocomplete="new-password" to prevent browsers autocomplete UI bug -->
  <v-text-field
    :value="value"
    class="input__standard input__standard--regular"
    outlined
    color="default"
    v-bind="$attrs"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    autocomplete="new-password"
    @input="input"
    @click:append="showPassword = !showPassword"
  />
</template>
<script>
export default {
  name: 'StandardPasswordField',
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showPassword: false,
    }
  },

  methods: {
    input(value) {
      this.$emit('input', value)
    },
  },
}
</script>
