<template>
  <v-container class="settings">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
    </v-app-bar>

    <v-row class="settings__container">
      <v-col cols="12">
        <div class="settings__content">
          <h1 class="settings__heading text">Account Settings</h1>

          <v-subheader class="px-0">
            <h3 class="subtitle">General</h3>
          </v-subheader>

          <StandardTextField
            class="mb-4"
            label="Email"
            disabled
            hide-details
            :value="authUser.email"
          />

          <TextFieldWithAppend
            class="mb-4"
            label="Password"
            readonly
            hide-details
            :disabled="isLoading"
            type="password"
            value="00000000"
          >
            <template #append>
              <v-btn
                text
                small
                depressed
                class="v-btn--append"
                @click="showDialog('password')"
              >
                Change
              </v-btn>
            </template>
          </TextFieldWithAppend>
        </div>
      </v-col>
    </v-row>

    <ChangePasswordDialog
      v-if="dialog.password"
      @closed="hideDialog('password')"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import StandardTextField from '@/components/fields/StandardTextField'
import TextFieldWithAppend from '@/components/fields/TextFieldWithAppend'
import ChangePasswordDialog from '@/components/account/ChangePasswordDialog'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
  components: {
    StandardTextField,
    TextFieldWithAppend,
    ChangePasswordDialog,
    AppBarNavIcon,
  },

  data() {
    return {
      dialog: {
        phone_number: false,
        password: false,
        delete: false,
      },
      authUser: {},
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
    }
  },

  computed: {
    ...mapState('user', ['isLoading']),
  },

  created() {
    this.$api.get('/auth/me').then((res) => {
      this.authUser = res.data.data
    })
  },

  methods: {
    showDialog(dialog) {
      this.dialog[dialog] = true
    },

    hideDialog(dialog) {
      this.dialog[dialog] = false
    },
  },
}
</script>

<style lang="scss" scoped>
.settings {
  .settings__container {
    max-width: 806px;
    margin: 0 auto;
  }

  .btn--delete {
    background-color: #fff;
    border-radius: 12px;
    color: #ff5252;
    font-weight: normal;
    transition: 0.2s;
    &:hover {
      color: #fff;
      background-color: #cc3f62;
    }
    .v-btn__content {
      letter-spacing: normal !important;
    }

    .v-input .v-input__control .v-input__slot .v-input__append-inner {
      margin-top: 0 !important;
    }
    .v-text-field .v-input__prepend-inner,
    .v-text-field .v-input__append-inner {
      align-self: center;
    }
  }
}
</style>
