<template>
  <div>
    <v-dialog v-if="token" v-model="dialog" persistent width="500">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <v-card>
            <v-card-title>
              <h3 class="text">Change Password</h3>
            </v-card-title>
            <v-card-subtitle class="mt-3"> Enter New Password </v-card-subtitle>
            <v-card-text>
              <ValidationProvider
                v-slot="{ errors }"
                name="Password"
                rules="required"
                vid="new_password"
              >
                <StandardPasswordField
                  key="password"
                  v-model="password"
                  label="Password"
                  required
                  :error-messages="errors"
                  :disabled="isLoading"
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="btn--secondary"
                rounded
                text
                small
                :disabled="isLoading"
                @click="closed"
              >
                Cancel
              </v-btn>
              <v-btn
                class="btn--primary"
                type="submit"
                rounded
                text
                small
                :loading="isLoading"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
    <VerifyTokenDialog
      v-else
      action="change your password"
      @success="setToken"
      @cancel="closed"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axios from 'axios'
import VerifyTokenDialog from './VerifyTokenDialog'
import StandardPasswordField from '@/components/fields/StandardPasswordField'
import '@/validations/settings'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VerifyTokenDialog,
    StandardPasswordField,
  },

  data() {
    return {
      dialog: true,
      currentPassword: '',
      password: '',
      token: '',
    }
  },

  computed: {
    ...mapState('accountSettings', ['isLoading']),
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('closed')
      }
    },
  },

  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch('accountSettings/changePassword', {
          old_password: this.currentPassword,
          new_password: this.password,
          new_password_confirmation: this.password,
          headers_token: this.token,
        })

        this.$store.dispatch(
          'showSuccessSnackbar',
          'Your password has been changed.'
        )

        this.closed()
      } catch (error) {
        if ('errors' in error.response.data) {
          this.$refs.form.setErrors(error.response.data.errors)
        } else {
          this.$store.dispatch('showErrorSnackbar', error.response.data.message)
        }
      }
    },

    closed() {
      this.dialog = false
    },

    setToken(data) {
      this.token = data.token
      this.currentPassword = data.currentPassword
    },

    destroyed() {
      delete axios.defaults.headers.post['verification-token']
      this.token = ''
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
  },
}
</script>
